<template>
<div class="date">
    <span class="Timeweek">今天是：{{$util.Timeweek()}}  </span>
    <span> 天气预报：</span>
    <iframe  ref="tianqi" class="tianqi" allowtransparency="true" frameborder="0" width="140" height="36" scrolling="no" src="//tianqi.2345.com/plugin/widget/index.htm?s=3&z=2&t=0&v=0&d=3&bd=0&k=&f=0080ff&ltf=0080ff&htf=0080ff&q=1&e=1&a=1&c=54511&w=140&h=36&align=center"></iframe>
</div>
   
</template>
<script>
export default {
    data(){
        return{

        }
    },
    created(){
    }, 
    watch:{
    },
    methods:{
       

    }
    
}
</script>
<style lang="less" scoped>
.date{
    color:#3e9aff;
    display: flex;
    align-items: center;
    min-width: 480px;
    .Timeweek{
        padding-right: 16px;
    }
    .tianqi{
        margin-top: -4px;
        .weather .cross-simple-icon dd .wea-more i {
        display: none;
    }
  }
}

</style>