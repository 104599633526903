   const list = [{
           "menuId": "1",
           "menuName": "量表管理",
           "menuIcon": "http://pub-static.aokaoyun.com/8de84f42bfdb44f28def06f146763813",
           "menuFlag": "1",
           "menuFuntype": "0",
           "orderNum": "2",
           "permission": "api:v1:newmenu:userMenuV1",
           "menuIconWhite": "https://pub-static.aokaoyun.com/image/2021-04-01/%E9%87%8F%E8%A1%A8%E7%99%BD%E8%89%B2.png",
           "menuIconBlue": "https://pub-static.aokaoyun.com/image/2021-04-01/%E9%87%8F%E8%A1%A8%E8%93%9D%E8%89%B2.png",
           "children": [
                  {
                      "menuId": "11",
                      "menuName": "量表",
                      "menuParent": "10",
                      "menuFlag": "1",
                      "menuUrl": "gauge",
                      "menuFuntype": "1",
                      "permission": "topic:team:mission_exam:list"
                  },
               {
                   "menuId": "12",
                   "menuName": "套餐设置",
                   "menuParent": "10",
                   "menuFlag": "1",
                   "menuUrl": "packageSet",
                   "menuFuntype": "1",
                   "permission": "sys:company,release:company:list",
               }
                   
           ]
       },
       {
           "menuId": "2",
           "menuName": "科普知识",
           "menuIcon": "http://pub-static.aokaoyun.com/8de84f42bfdb44f28def06f146763813",
           "menuFlag": "1",
           "menuUrl": "science",
           "menuFuntype": "0",
           "orderNum": "2",
           "permission": "api:v1:newmenu:userMenuV1",
           "menuIconWhite": "https://pub-static.aokaoyun.com/image/2021-04-01/%E6%B5%8B%E8%AF%84%E7%99%BD.png",
           "menuIconBlue": "https://pub-static.aokaoyun.com/image/2021-04-01/%E6%B5%8B%E8%AF%84%E8%93%9D.png",
           "children": []
       },
       {
           "menuId": "3",
           "menuName": "学习课程",
           "menuIcon": "http://pub-static.aokaoyun.com/8de84f42bfdb44f28def06f146763813",
           "menuFlag": "1",
           "menuFuntype": "0",
           "orderNum": "2",
           "permission": "api:v1:newmenu:userMenuV1",
           "menuIconWhite": "https://pub-static.aokaoyun.com/image/2021-04-01/%E5%92%A8%E8%AF%A2%E7%99%BD.png",
           "menuIconBlue": "https://pub-static.aokaoyun.com/image/2021-04-01/%E5%92%A8%E8%AF%A2%E7%99%BD.png",
           "children": [{
                   "menuId": "31",
                   "menuName": "课程管理",
                   "menuParent": "10",
                   "menuFlag": "1",
                   "menuUrl": "onlineCourses",
                   "menuFuntype": "1",
                   "permission": "topic:team:mission_exam:list"
               },
               {
                   "menuId": "32",
                   "menuName": "课时管理",
                   "menuParent": "10",
                   "menuFlag": "1",
                   "menuUrl": "coursesTime",
                   "menuFuntype": "1",
                   "permission": "sys:company,release:company:list"
               },
           ]
       },
       {
           "menuId": "4",
           "menuName": "放松音乐",
           "menuIcon": "http://pub-static.aokaoyun.com/8de84f42bfdb44f28def06f146763813",
           "menuFlag": "1",
           "menuUrl": "music",
           "menuFuntype": "0",
           "menuMemo": "",
           "menuCreatedate": "2021-03-26 14:52:13",
           "compId": "",
           "orderNum": "9",
           "permission": "api:course:save,api:minio:upload,api:dictType:find",
           "menuPath": "api/course/getList,api/course/getCourse,api/dictType/find",
           "menuIconWhite": "https://pub-static.aokaoyun.com/image/2021-04-01/%E6%B5%8B%E8%AF%84%E7%99%BD.png",
           "menuIconBlue": "https://pub-static.aokaoyun.com/image/2021-04-01/%E6%B5%8B%E8%AF%84%E8%93%9D.png",
           "children": []
       }, {
        "menuId": "5",
        "menuName": "咨询师管理",
        "menuIcon": "http://pub-static.aokaoyun.com/8de84f42bfdb44f28def06f146763813",
        "menuFlag": "1",
        "menuUrl": "consultant",
        "menuFuntype": "0",
        "menuMemo": "",
        "menuCreatedate": "2021-03-26 14:52:13",
        "compId": "",
        "orderNum": "9",
        "permission": "api:course:save,api:minio:upload,api:dictType:find",
        "menuPath": "api/course/getList,api/course/getCourse,api/dictType/find",
        "menuIconWhite": "https://pub-static.aokaoyun.com/image/2021-04-01/%E6%B5%8B%E8%AF%84%E7%99%BD.png",
        "menuIconBlue": "https://pub-static.aokaoyun.com/image/2021-04-01/%E6%B5%8B%E8%AF%84%E8%93%9D.png",
        "children": []
    }, {
           "menuId": "6",
           "menuName": "机构管理",
           "menuIcon": "http://pub-static.aokaoyun.com/8de84f42bfdb44f28def06f146763813",
           "menuFlag": "1",
           "menuFuntype": "0",
           "orderNum": "2",
           "permission": "api:v1:newmenu:userMenuV1",
           "menuIconWhite": "https://pub-static.aokaoyun.com/image/2021-04-01/%E8%AF%BE%E5%A0%82%E7%99%BD.png",
           "menuIconBlue": "https://pub-static.aokaoyun.com/image/2021-04-01/%E5%9B%A2%E4%BD%93%E8%93%9D.png",
           "children": [{
                   "menuId": "61",
                   "menuName": "商户维护",
                   "menuParent": "10",
                   "menuFlag": "1",
                   "menuUrl": "maintain",
                   "menuFuntype": "1",
                   "permission": "topic:team:mission_exam:list"
               },
               {
                   "menuId": "62",
                   "menuName": "机构账户",
                   "menuParent": "10",
                   "menuFlag": "1",
                   "menuUrl": "orgAccount",
                   "menuFuntype": "1",
                   "permission": "sys:company,release:company:list"
               },
           ]
       },
       {
               "menuId": "7",
               "menuName": "资源配置",
               "menuIcon": "http://pub-static.aokaoyun.com/704ef39967974dacaea87735ed9650d4",
               "menuFlag": "1",
               "menuUrl": "resourceSet",
               "menuFuntype": "0",
               "orderNum": "1",
               "permission": "topic:list,api:v2:oss:qiniuyun:gettoken,api:v1:newmenu:userMenuV1",
               "menuIconWhite": "https://pub-static.aokaoyun.com/image/2021-04-01/%E9%87%8F%E8%A1%A8%E7%99%BD%E8%89%B2.png",
               "menuIconBlue": "https://pub-static.aokaoyun.com/image/2021-04-01/%E9%87%8F%E8%A1%A8%E8%93%9D%E8%89%B2.png",
               "children": []
       },{
           "menuId": "8",
           "menuName": "数据字典",
           "menuIcon": "http://pub-static.aokaoyun.com/8de84f42bfdb44f28def06f146763813",
           "menuFlag": "1",
           "menuFuntype": "0",
           "orderNum": "2",
           "permission": "api:v1:newmenu:userMenuV1",
           "menuIconWhite": "https://pub-static.aokaoyun.com/image/2021-04-01/%E8%BF%90%E8%90%A5%E7%99%BD.png",
           "menuIconBlue": "https://pub-static.aokaoyun.com/image/2021-04-01/%E5%9B%A2%E4%BD%93%E8%93%9D.png",
           "children": [{
                   "menuId": "81",
                   "menuName": "服务分类",
                   "menuParent": "10",
                   "menuFlag": "1",
                   "menuUrl": "serviceType",
                   "menuFuntype": "1",
                   "permission": "topic:team:mission_exam:list"
               },{
                "menuId": "82",
                "menuName": "科普分类",
                "menuParent": "10",
                "menuFlag": "1",
                "menuUrl": "psyType",
                "menuFuntype": "1",
                "permission": "topic:team:mission_exam:list"
            },{
                "menuId": "84",
                "menuName": "量表分类",
                "menuParent": "10",
                "menuFlag": "1",
                "menuUrl": "gaugeType",
                "menuFuntype": "1",
                "permission": "topic:team:mission_exam:list"
            },{
                "menuId": "85",
                "menuName": "课堂分类",
                "menuParent": "10",
                "menuFlag": "1",
                "menuUrl": "coursesType",
                "menuFuntype": "1",
                "permission": "topic:team:mission_exam:list"
            },{
                "menuId": "86",
                "menuName": "心理机构地图",
                "menuParent": "10",
                "menuFlag": "1",
                "menuUrl": "navigationMap",
                "menuFuntype": "1",
                "permission": "topic:team:mission_exam:list"
            },{
                "menuId": "87",
                "menuName": "学校库",
                "menuParent": "10",
                "menuFlag": "1",
                "menuUrl": "school",
                "menuFuntype": "1",
                "permission": "topic:team:mission_exam:list"
            },{
                "menuId": "88",
                "menuName": "上报类型",
                "menuParent": "10",
                "menuFlag": "1",
                "menuUrl": "reportType",
                "menuFuntype": "1",
                "permission": "topic:team:mission_exam:list"
            },{
                "menuId": "89",
                "menuName": "援助热线",
                "menuParent": "10",
                "menuFlag": "1",
                "menuUrl": "hotline",
                "menuFuntype": "1",
                "permission": "topic:team:mission_exam:list"
            },

           ]
       },{
        "menuId": "9",
        "menuName": "系统设置",
        "menuIcon": "http://pub-static.aokaoyun.com/8de84f42bfdb44f28def06f146763813",
        "menuFlag": "1",
        "menuFuntype": "0",
        "orderNum": "2",
        "permission": "api:v1:newmenu:userMenuV1",
        "menuIconWhite": "https://pub-static.aokaoyun.com/image/2021-04-01/%E5%9B%A2%E4%BD%93%E7%99%BD.png",
        "menuIconBlue": "https://pub-static.aokaoyun.com/image/2021-04-01/%E5%9B%A2%E4%BD%93%E8%93%9D.png",
        "children": [{
                "menuId": "91",
                "menuName": "系统用户",
                "menuParent": "10",
                "menuFlag": "1",
                "menuUrl": "systemUser",
                "menuFuntype": "1",
                "permission": "topic:team:mission_exam:list"
            },
        ]
    }, {
           "menuId": "10",
           "menuName": "其他配置",
           "menuIcon": "https://pub-static.aokaoyun.com/image/2021-04-01/%E8%AF%BE%E5%A0%82%E7%99%BD.png",
           "menuFlag": "1",
           "menuUrl": "other",
           "menuFuntype": "0",
           "orderNum": "6",
           "permission": "kepu:news:getlist,kepu:themetype:list,api:v1:sys:config:info:oss_channel",
           "menuIconWhite": "https://pub-static.aokaoyun.com/image/2021-04-01/%E8%AF%BE%E5%A0%82%E7%99%BD.png",
           "menuIconBlue": "https://pub-static.aokaoyun.com/image/2021-04-01/%E8%AF%BE%E5%A0%82%E8%93%9D.png",
           "children": []
       },
   ]
   export default list