<template>
  <div class="panel">
    <el-container>
      <el-header class="header">
        <div class="left">
          <img src="../assets/images/logo.jpg" alt />
          <span class="title">心理健康服务云平台（管理端）</span>
        </div>
        <div class="right">
          <Tianqi></Tianqi>
          <img src="../assets/images/logo.jpg" alt />
          <el-dropdown trigger="click" class="cursor_pointer">
            <span class="el-dropdown-link">{{userInof.userName}}
             <i class="el-icon-caret-top el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native="showModal">修改密码</el-dropdown-item> -->
              <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container>
        <el-aside width="207px" class="aside">
          <!-- <el-scrollbar wrap-class="scrollbar-wrapper"> -->
            <el-menu class="el-menu-vertical-demo" :unique-opened="true" :default-active="selectIndex" @select="handleSelect"
              @open="handleOpen" @close="handleClose">
              <!-- <el-menu-item class="son" index="home" @click="go('home')" :class="{'is-active':isSelect('home')}">
                <img v-if="isSelect('home')" src="@/assets/images/home_true.png" class="icon_img" />
                <img v-else src="@/assets/images/home_false.png" class="icon_img" />
                <span slot="title">首页</span>
              </el-menu-item> -->
              <div v-if="menuList&&menuList.length>0">
                <div v-for="(item,index) in menuList" :key="index">
                  <el-submenu class="submenu" :index="item.menuId" v-if="(item.children&&item.children.length>0)||!item.menuUrl">
                    <template slot="title">
                      <img :src="item.menuIconWhite" class="icon_img" />
                      <span>{{item.menuName}}</span>
                    </template>
                    <div v-for="(row,i) in item.children" :key="i">
                      <el-menu-item :index="row.menuUrl" @click="go(row.menuUrl,i)"
                        :class="{'is-active':isSelect(row.menuUrl)}"> {{row.menuName}} </el-menu-item>
                    </div>
                  </el-submenu>
                  <el-menu-item class="son" :index="item.menuUrl" v-else @click="go(item.menuUrl,index)">
                    <img v-if="isSelect(item.menuUrl)" :src="item.menuIconBlue" class="icon_img" alt="" />
                    <img v-else :src="item.menuIconWhite" class="icon_img" alt="" />{{item.menuName}}
                  </el-menu-item>
                </div>
              </div>
            </el-menu>
          <!-- </el-scrollbar> -->
        </el-aside>
        <el-container>
          <el-main class="main">
            <router-view></router-view>
          </el-main>
          <!-- <el-footer>
            <span>版权所有  ©{{new Date().getFullYear()}} 深圳市慈树健康科技有限公司</span>
          </el-footer> -->
        </el-container>
      </el-container>
    </el-container>
    <!-- 修改密码 -->
    <modal :obj="{
        title: '修改密码',
        dialog: closeDialog,
        dialogText: 'closeDialog',
        width: '500px',
        form: form,
        ref: 'form',
        okText: '确定',
        cancelText: '取消',
      }" @submit="updatePWD">
      <el-form :model="form" ref="form" :rules="rules" label-width="100px">
        <el-form-item label="旧密码：" prop="oldpwd">
          <el-input v-model="form.oldpwd" type="password" maxlength="12" placeholder="请输入旧密码" minlength="6"
            show-password>
          </el-input>
        </el-form-item>
        <el-form-item label="新密码：" prop="password">
          <el-input v-model="form.password" type="password" maxlength="12" placeholder="请输入新密码" show-password>
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="password1">
          <el-input v-model="form.password1" type="password" maxlength="12" placeholder="请输入确认密码" show-password>
          </el-input>
        </el-form-item>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import modal from "@/components/modal";
  import Tianqi from "@/components/Tianqi";
  import list from "./panelList"
  import {
    logout,
    updatePWD,
  } from "@/api/api";
  export default {
    components: {
      modal,
      Tianqi
    },
   data() {
      return {
        closeDialog: false,
        form: {
          oldpwd: "",
          password: "",
          password1: "",
        },
        rules: {
          oldpwd: [{
            required: true,
            message: "请输入旧密码"
          }, {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符'
          }],
          password: [{
            required: true,
            message: "请输入新密码"
          }, {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符'
          }],
          password1: [{
            required: true,
            message: "请输入确认密码"
          }, {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符'
          }],
        },
        selectIndex: "", //激活目录的index
        openIndex: [], //子目录展开的index
        closeIndex: "", //子目录收起的index
        menuList:list
      };
    },
    computed: {
      userInof() {
        return this.$store.state.userInof;
      },
    },
    watch: {
      "form.password"(v) {
        this.form.password = this.$util.inputSpecial(v)
      },
      "form.oldpwd"(v) {
        this.form.oldpwd = this.$util.inputSpecial(v)
      },
      "form.password1"(v) {
        this.form.password1 = this.$util.inputSpecial(v)
      },
    },
    created() {
      this.selectIndex = this.$route.name;
    },
    mounted() {},
    methods: {
      // 点击菜单
      handleSelect(index, indexPath) {
        this.selectIndex = index;
      },
      // 子目录展开
      handleOpen(index, indexPath) {
        this.openIndex.push(index);
      },
      // 子目录收起
      handleClose(index, indexPath) {
        let num = this.openIndex.indexOf(index);
        this.openIndex.splice(num, 1);
      },
      isSelect(val) {
        return this.$route.path == "/panel/" + val
      },
      // 修改密码弹框
      showModal() {
        this.closeDialog = true;
      },
      go(path) {
        this.$router.push({
          name: path
        })
      },
      // 退出
      logout() {
        this.$request(logout).then((res) => {
          if (res.code == 0) {
            localStorage.removeItem("access_token");
            this.$util.message("suc", "退出成功");
            this.$router.push({
              name: "login"
            });
          }
        });
      },
      // 修改密码
      updatePWD() {
        this.$refs.form.validate((result) => {
          if (result) {
            if (this.form.password != this.form.password1) {
              this.$util.message("err", "新密码和确认密码不一致");
              return;
            }
            this.$request(updatePWD, this.form).then((res) => {
              if (res.code === 0) {
                this.$util.message("suc", "操作成功");
                this.closeDialog = false;
              }
            });
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .panel {
    background-color: #EEF2F7;
    z-index: 1;

    .header {
      width: 100%;
      height: 64px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;

      .left {
        margin-left: 40px;
        line-height: 64px;

        .title {
          font-size: 22px;
          color: #3e9aff;
          font-weight: 500;
          padding-left: 8px;
        }

        img {
          width: 40px;
          height: 40px;
          vertical-align: middle;
          margin-top: -12px;
        }
      }

      .right {
        height: 64px;
        margin-right: 20px;
        display: flex;
        align-items: center;


        img {
          display: inline-block;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          vertical-align: middle;
        }

        span {
          font-size: 14px;
          margin-left: 5px;
        }
      }
    }

    /deep/.scrollbar-wrapper {
      margin-right: -19px !important;
    }

    .el-container {
      height: calc(100vh - 60px);

    }

    /deep/.el-submenu__title i {
      color: #fff;
    }

    .aside {
      color: #fff !important;

      .el-menu-vertical-demo {
        height: 100%;
        color: #fff;
      }

      /deep/.el-menu-item.is-active,
      .el-menu-item:hover {
        padding-left: 32px !important;
        margin: 0px 8px;
        color: #2785ff !important;
        background: #fff !important;

      }

      /deep/.son.is-active,
      .son:hover {
        padding-left: 13px !important;
        margin: 0px 8px;
      }

      // 选中当前目录为背景色
      /deep/ .is-opened {
        color: #2785ff !important;
      }

      /deep/.el-menu-item>.is-active {
        padding-left: 12px !important;
      }

      /deep/.el-submenu .el-submenu__title {
        border-bottom-color: #409EFF;
        color: #fff !important;
        height: 40px;
        line-height: 40px;
      }

      // 选中当前目录为蓝色
      .el-menu-vertical-demo>.is-active,
      .el-menu--inline>.is-active {
        background: #edf6fe;
      }

      .el-submenu>.is-active>div {
        color: #fff !important;
      }

      .el-menu {
        background: none !important;
        border-right:  none !important;
      }

      // 选中有子集的-li的子集margin
      .el-menu-vertical-demo>.el-submenu {
        margin: 0px;

        .is-active,
        :hover {
          min-width: 154px;
          margin: 8px 8px;
          padding-left: 38px !important;
        }
      }

      .el-menu--inline,
      .el-menu--inline:hover {
        background: none !important;
      }

      // 有二级的展开样式
      .el-menu-vertical-demo>.is-opened,
      .el-menu-vertical-demo>.is-opened /deep/.el-menu--inline,
      .el-menu--inline:hover {
        background: #fff !important;

      }

      .el-menu-vertical-demo>.is-opened /deep/.el-submenu__title {
        background: #fff !important;
      }

      // 有子集的-li的margin
      .el-menu-vertical-demo>.el-submenu {
        margin: 0px !important;
        padding-left: 0px !important;
      }

      // 当子集呗选中，自己的背景色
      /deep/.is-active>div {
        background: none !important;
      }

      .el-menu-item,
      .el-submenu__title {
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
        color: #fff !important;
      }

      /deep/.el-menu {
        div {
          .no_active {
            color: #fff !important;
            background: none !important;
          }
        }
      }
    }

    /deep/.el-submenu .el-menu-item {
      min-width: 180px !important;
    }

    /deep/.el-menu--inline,
    .el-menu--inline:hover {
      background: none !important;
    }

    /deep/.el-submenu__title,
    .el-submenu__title:hover {
      background: none !important;
    }

    .main {
      padding: 0 16px;
      min-width: 730px;
      padding-bottom: 20px;
    }

    .el-aside {
      background: linear-gradient(180deg, #55BFFF 0%, #5082FF 64%, #3350FC 100%);
      padding-top: 24px;
    }

    .el-footer {
      text-align: center;
      line-height: 60px;
      background: #eef2f7;

      span {
        font-size: 14px;
        color: #999999;
      }
    }

    .icon_img {
      margin: 0px 5px;
    }

  }
</style>